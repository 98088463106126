<script>
import { h } from 'vue'

export default {
    name: "tab-vertical",

    emits: ['closed', 'index'],

    props: {
        defaultIndex: {
            type: Number,
            default: 0,
        },

        manualIndex: {
            type: Number
        }
    },

    data: function () {
        return {
            index: this.defaultIndex,
            closed: false
        };
    },

    computed: {
        slots: function () {
            return this.$slots.default?.();
        },
    },

    methods: {
        setActive: function (index) {
            if (this.index === index) {
                this.closed = !this.closed;
            } else {
                this.index = index;
                this.closed = false;
            }
        },
    },

    watch: {
        closed: {
            immediate: true,
            handler: function (value) {
                this.$emit('closed', value);
            },
        },

        manualIndex: {
            handler: function (index) {
                if (index !== null) {
                    this.index = index;
                }
            },
        },

        index: {
            immediate: true,
            handler: function (value) {
                this.$emit('index', value);
            },
        }
    },

    mounted() {
        // console.log(this.$slots.default())
    },

    render: function () {
        return h('div', {
            class: 'row flex-row-reverse',
        }, [
            h('div', { class: {'col flex-grow-0 flex-shrink-1': !this.closed, 'col-12': this.closed} }, [
                h('ul', { class: 'nav nav-tabs nav-tabs-custom nav-justified vertical-modified bg-white ps-1 m-0' }, this.slots?.filter(item => item.children !== 'v-if').map((item, index) =>
                    h('li', { key: index, class: 'nav-item cursor-pointer mb-1', onClick: () => {this.setActive(index)}, title: item?.props?.title }, [
                        h('a', { class: ['nav-link rounded-custom px-1 py-2', {active: index === this.index, 'text-danger': item?.props?.marked}] }, (item?.children?.icon?.() || item?.props?.title))
                    ])
                ))
            ]),
            h('div', { class: {'col flex-grow-1': !this.closed, 'd-none': this.closed} }, [
                h('div', { class: 'tab-content' }, this.slots?.filter(item => item.children !== 'v-if').map((item, index) =>
                    h('div', { key: index, class: ['tab-pane', { active: index ===  this.index}] }, item?.children?.default())
                ))
            ])
        ])
    },
}
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

.vertical-modified {
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.nav-tabs-custom {
    border-top-left-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
    padding-inline-end: 40px;
    box-shadow: $box-shadow;
    border: $card-border-width solid $card-border-color;

    .nav-item {
        .nav-link.text-danger {
            background-color: lighten($danger, 25%);
            &::after {
                background-color: $danger;
            }
        }
    }
}

.rounded-custom {
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    &::after {
        height: 100% !important;
        width: 2px !important;
        right: 0px !important;
        top: 0 !important;
        left: unset !important;
        bottom: 0 !important;
    }
}

</style>