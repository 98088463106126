<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import FileInput from "../elements/file-input.vue";
import Tree from "../elements/tree.vue";
// import InvoiceItems from "../elements/invoice-items.vue";
import BillablesManager from "../elements/billables-manager.vue";

export default defineComponent({
    name: "invoice-form",

    emits: ['saved', 'updated'],

    props: {
        invoice: {
            type: Object,
            required: true
        },

        fixed: {
            type: Boolean,
            default: false
        },

        own: {
            type: Boolean,
            default: false
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        accessible: {
            type: Boolean,
            default: false
        },

        interaction: {
            type: Boolean,
            default: false
        },

        projectId: {
            type: Number
        },

        editable: {
            type: Boolean,
            default: true
        },

        showed: {
            type: Boolean,
            default: true
        }
    },

    components: {BillablesManager, Tree, FileInput},

    computed: {
        ...mapGetters({
            'loading': 'invoice/loading',
            'states': 'invoice/states',
            'errors': 'invoice/errors',
            invoiceTypes: 'invoiceType/all',
            partners: 'partner/all',
            implementings: 'implement/all',
        }),

        defaultDeadline: function () {
            const relative = this.$store.getters['app/configuration']?.invoices?.default_deadline;

            const conf = relative?.split(' ');

            const now = new Date();

            if (! conf?.length) {
                return now.formattedDate();
            }

            if (conf[1] === 'day') {
                now.setDate(now.getDate() + Number(conf[0]));
            }
            now.setHours(24);
            now.setMinutes(0);
            now.setMilliseconds(0);
            now.setSeconds(0);

            return now.formattedDate();
        },

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        externalFileStore: function () {
            return this.$store.getters['app/configuration']?.projects.required_file_type === 'link';
        },

        localFileStore: function () {
            return this.$store.getters['app/configuration']?.projects.required_file_type === 'file';
        },
    },

    data: function () {
        return {
            model: {},
            implementParts: [],
            billables: [],
            sent: null,
            settled: null,
            canceled: null,
            initedProjectId: this.projectId
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({query: {with: ['items', 'target', 'type']}}, this.model)

            if (!this.doNotSave) {
                return this.$store.dispatch(`invoice/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create') {
                        let path = '/';
                        const prefix = this.own ? 'my-' : (this.accessible ? 'accessible' : '');
                        path +=  this.interaction ? 'interactions/' : 'invoices/';
                        path += prefix + 'invoices';
                        this.$router.push(this.$t('routes.'+path+'/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                this.$store.dispatch('invoice/setItem', this.model)
                this.$emit('updated', this.model)
            }
        },

        fetchInvoiceTypes: function () {
            this.$store.dispatch('invoiceType/all')
        },

        fetchPartners: function () {
            this.$store.dispatch('partner/all')
        },

        fetchImplements: function () {
            this.$store.dispatch('implement/all', {project_id: this.initedProjectId, with: ['implementParts', 'implementParts.billables']})
        },

        setModel: function (model = {}) {
            const base = {
                target_type: 'Appon\\Partners\\Models\\Partner',
                deadline: this.defaultDeadline
            }

            if (!model.id) {
                base.sent_at = (new Date()).formattedDate()
            }

            this.model = Object.assign(base, this.dynamicSkeleton, JSON.parse(JSON.stringify(model)));
        },

        setFile: function (event, prop = 'file_file') {
            this.model[prop] = event.target.files[0]
        },

        setParts: function (implement) {
            this.model.implement_id = Number(implement.target.value);
            this.implementParts = this.implementings.find(impl => impl.id === this.model.implement_id)?.implement_parts;
        },

        setProp: function (prop, value) {
            if (
                (value && this.model[prop])
                || (!value && !this.model[prop])
            ) {
                return;
            }

            this.model[prop] = value ? (new Date()).formattedDate() : null;
        },

        setBillableList: function (part, bool) {
            if (bool) {
                this.billables.push(...part.billables.map(b => {
                    if (b?.pivot) {
                        Object.assign(b, b.pivot);
                    }
                    if (! b.billable) {
                        b.billable = {
                            name: b.name
                        };
                    }
                    return b;
                }));
            } else {
                part.billables.forEach(billable => {
                    this.billables.splice(this.billables.findIndex(bill => bill.id === billable.id), 1);
                })
            }
            this.model.billables = this.billables;
        },

        init: function () {
            this.settled = !!this.model.settled_at;
            this.sent = !!this.model.sent_at;
            this.canceled = !!this.model.canceled_at;
            this.billables = this.invoice?.billables;
        },

        updateModelBillable: function () {
            this.model.billables = this.billables;
        },
    },

    watch: {
        invoice: function (value) {
            this.setModel(value);
            this.init();
            if (!this.initedProjectId && value.reference_type === 'Appon\\Projects\\Models\\Project') {
                this.initedProjectId = value.reference_id;
            }
        },

        initedProjectId: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.fetchImplements()
                }
            },
        },

        projectId: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.initedProjectId = id;
                }
            },
        },

        settled: function (value) {
            this.setProp('settled_at', value);
        },

        canceled: function (value) {
            this.setProp('canceled_at', value);
        },

        sent: function (value) {
            this.setProp('sent_at', value);
        },

        defaultDeadline: {
            immediate: true,
            handler: function (value) {
                if (! this.model.deadline) {
                    this.model.deadline = value;
                }
            }
        }
    },

    created() {
        this.setModel(this.invoice);
        this.init();
        this.fetchInvoiceTypes()
        this.fetchPartners()
    },

    unmounted() {
        this.$store.dispatch('implement/clearAll')
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="w-100 h-100 p-3">
                                <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                                    <i class="fas fa-file-invoice"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-4">
                            <label
                                :class="{'text-danger': states.code === false}"
                                class="form-label"
                                for="code"
                            >{{ $t('invoices::invoices.columns.code').ucFirst() }} *</label
                            >

                            <input
                                id="code"
                                v-model="model.code"
                                :class="{'border-danger': states.code === false}"
                                :placeholder="$t('invoices::invoices.placeholders.code').ucFirst()"
                                class="form-control"
                                type="text"
                            />
                            <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                        </div>
                        <div class="col-lg-3 mb-4" v-if="!fixed">
                            <label
                                :class="{'text-danger': states.target_id === false}"
                                class="form-label"
                                for="target_id"
                            >{{ $t('invoices::invoices.columns.target_id').ucFirst() }}</label>
                            <select
                                id="target_id"
                                v-model="model.target_id"
                                :class="{'border-danger': states.target_id === false}"
                                class="form-select"
                                name="target_id"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('invoices::invoices.placeholders.target_id').ucFirst() }}
                                </option>
                                <option v-for="(item, key) in partners" :key="key" :value="item.id">{{
                                        item.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.target_id === false}" class="invalid-feedback">
                                {{ errors.target_id }}
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.type_id === false}"
                                class="form-label"
                                for="type_id"
                            >{{ $t('invoices::invoices.columns.type_id').ucFirst() }} *</label>
                            <select
                                id="type_id"
                                v-model="model.type_id"
                                :class="{'border-danger': states.type_id === false}"
                                class="form-select"
                                name="type_id"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('invoices::invoices.placeholders.type_id').ucFirst() }}
                                </option>
                                <option v-for="(invoiceType, key) in invoiceTypes" :key="key" :value="invoiceType.id">{{
                                        invoiceType.translation_key ? $t(invoiceType.translation_key).ucFirst() : invoiceType.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.type_id === false}" class="invalid-feedback">
                                {{ errors.type_id }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="w-100 h-100 p-3">
                                <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.achieved_at === false}"
                                class="form-label"
                                for="achieved_at"
                            >{{ $t('invoices::invoices.columns.achieved_at').ucFirst() }} *</label
                            >
                            <input
                                id="achieved_at"
                                v-model="model.achieved_at"
                                :class="{'border-danger': states.achieved_at === false}"
                                :placeholder="$t('invoices::invoices.placeholders.achieved_at').ucFirst()"
                                class="form-control"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.achieved_at === false}" class="invalid-feedback">
                                {{ errors.achieved_at }}
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.deadline === false}"
                                class="form-label"
                                for="deadline"
                            >{{ $t('invoices::invoices.columns.deadline').ucFirst() }} *</label
                            >
                            <input
                                id="deadline"
                                v-model="model.deadline"
                                :class="{'border-danger': states.deadline === false}"
                                :placeholder="$t('invoices::invoices.placeholders.deadline').ucFirst()"
                                class="form-control"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.deadline === false}" class="invalid-feedback">
                                {{ errors.deadline }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row mb-4 pt-2 px-3">
                        <div class="col-12 bg-soft-primary rounded pt-3">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-primary">
                                        <i class="fas fa-paperclip"></i>
                                    </h3>
                                </div>
                                <div v-if="localFileStore" class="col-12 mb-4 ps-lg-5">
                                    <label
                                        :class="{'text-danger': states.file === false}"
                                        class="form-label"
                                        for="file"
                                    >{{ $t('invoices::invoices.columns.file_id').ucFirst() }}</label>
                                    <file-input
                                        id="file_file"
                                        name="file_file"
                                        :class="{'border-danger': states.file === false}"
                                        :placeholder="$t('interactions::interactions.interactions.invoice').ucFirst()"
                                        @change="setFile($event)"
                                    ></file-input>
                                    <div :class="{'d-block': states.file === false}" class="invalid-feedback">
                                        {{ errors.file }}
                                    </div>
                                </div>
                                <div v-if="externalFileStore" class="col-12 mb-4 ps-lg-5">
                                    <label
                                        :class="{'text-danger': states.file_link === false}"
                                        class="form-label"
                                        for="file_link"
                                    >{{ $t('invoices::invoices.columns.file_link').ucFirst() }}</label
                                    >
                                    <div class="input-group">
                                        <div class="input-group-text">
                                            <i class="fa fa-link"></i>
                                        </div>
                                        <input
                                            id="file_link"
                                            v-model="model.file_link"
                                            :class="{'border-danger': states.file_link === false}"
                                            :placeholder="$t('invoices::invoices.placeholders.file_link').ucFirst()"
                                            class="form-control"
                                            type="url"
                                        />
                                    </div>
                                    <div :class="{'d-block': states.file_link === false}" class="invalid-feedback">{{ errors.file_link }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="w-100 h-100 p-3">
                                <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                                    <i class="mdi mdi-checkbox-multiple-marked"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4">
                            <label
                                :class="{'text-danger': states.sent_at === false}"
                                class="form-label"
                                for="is_active"
                            >{{ $t('invoices::invoices.sent').ucFirst() }}</label
                            >
                            <div class="form-check form-switch form-switch-md mb-3 ps-0">
                                <input id="is_active"
                                       v-model="sent"
                                       class="form-check-input float-none mx-3"
                                       type="checkbox"
                                >
                            </div>
                            <div :class="{'d-block': states.sent_at === false}" class="invalid-feedback">
                                {{ errors.sent_at }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4">
                            <label
                                :class="{'text-danger': states.settled_at === false}"
                                class="form-label"
                                for="is_active"
                            >{{ $t('invoices::invoices.settled').ucFirst() }}</label
                            >
                            <div class="form-check form-switch form-switch-md mb-3 ps-0">
                                <input id="is_active"
                                       v-model="settled"
                                       class="form-check-input float-none mx-3"
                                       type="checkbox"
                                >
                            </div>
                            <div :class="{'d-block': states.settled_at === false}" class="invalid-feedback">
                                {{ errors.settled_at }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4">
                            <label
                                :class="{'text-danger': states.canceled_at === false}"
                                class="form-label"
                                for="is_active"
                            >{{ $t('invoices::invoices.canceled').ucFirst() }}</label
                            >
                            <div class="form-check form-switch form-switch-md mb-3 ps-0">
                                <input id="is_active"
                                       v-model="canceled"
                                       class="form-check-input float-none mx-3"
                                       type="checkbox"
                                >
                            </div>
                            <div :class="{'d-block': states.canceled_at === false}" class="invalid-feedback">
                                {{ errors.canceled_at }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="initedProjectId">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-center mb-4 border-bottom pb-2 text-primary">{{ $tc('invoices::items.item', 2).ucFirst() }}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 mb-4">
                        <label
                            :class="{'text-danger': states.implement_id === false}"
                            class="form-label"
                            for="implement_id"
                        >{{ $tc('projects::implements.implement', 2).ucFirst() }}</label>
                        <select
                            id="implement_id"
                            v-model="model.implement_id"
                            :class="{'border-danger': states.implement_id === false}"
                            class="form-select"
                            name="implement_id"
                            @input="setParts"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $tc('projects::implements.implement', 1).ucFirst() }}
                            </option>
                            <template v-for="(item, key) in implementings" :key="key" >
                                <option :value="item.id">{{
                                        item.name
                                    }}
                                </option>
                            </template>
                        </select>
                        <div :class="{'d-block': states.implement_id === false}" class="invalid-feedback">
                            {{ errors.implement_id }}
                        </div>
                    </div>
                    <div v-if="implementParts?.length" class="col-lg-4 mb-4">
                        <label
                            class="form-label"
                        >{{ $tc('projects::implement_parts.implement_part', 2).ucFirst() }}</label>
                        <tree prop="implement_parts" :items="implementParts" @select="setBillableList"></tree>
                    </div>
                </div>
            </template>
            <div class="row mb-4">
                <div class="col-12">
                    <billables-manager v-if="editable && showed" v-model="billables" :key="billables?.length" @update:amount="updateModelBillable"></billables-manager>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

    .option-lead {
        color: $dark;
        font-weight: 500;
        background-color: $light;
        font-size: 16px;
    }
</style>
